import { template } from 'lodash-es'

import 'onsenui/css/onsenui-core.min.css'
import 'onsenui/css/onsen-css-components.min.css'
import 'onsenui/css/ionicons/css/ionicons.css'
import './styles/app.css'

import ons from 'onsenui/esm'
import 'onsenui/esm/elements/ons-page'
import 'onsenui/esm/elements/ons-navigator'
import 'onsenui/esm/elements/ons-toolbar'
import 'onsenui/esm/elements/ons-button'
import 'onsenui/esm/elements/ons-icon'
import 'onsenui/esm/elements/ons-list'
import 'onsenui/esm/elements/ons-list-item'
import 'onsenui/esm/elements/ons-input'
import 'onsenui/esm/elements/ons-back-button'
import 'onsenui/esm/elements/ons-alert-dialog'
import 'onsenui/esm/elements/ons-alert-dialog-button'


export class PageHandler
  @checkBrowser: ->
    if ons.platform.getMobileOS() == 'other'
      ons.notification.alert(
        "Pro správné zobrazení použijte<br/>mobilní telefon.",
        title: "Pozor"
      )

  constructor: (pageData, responseStatus) ->
    @pageData = pageData || {}
    @responseStatus = responseStatus
    @appNavigator = document.querySelector('#appNavigator')
    @scanner = { geoCoords: null, qrCode: null }

  homePageInit: ->
    loginButton = $('#login-button')
    
    $('#username-field').on('keydown', (e) ->
      if e.key == 'Enter'
        loginButton.trigger(jQuery.Event('click'))
    )
    
    loginButton.on('click', =>
      form = new FormData(document.querySelector('#login-form'))
      if form.get('username').length == 0
        window.alert("Vyplňte do kolonky kód vstupenky")
        return

      loginButton.prop('disabled', true)
      $('.loading-spinner').show()
      fetch("/api/auth", method: 'POST', body: form)
        .then((resp) =>
          if resp.status != 200
            ons.notification.alert("#{resp.status} - #{resp.statusText}", title: "Chyba sítě")
            return
          resp.json()
        )
        .then((res) =>
          if res.error
            ons.notification.alert(res.error, title: "Nelze přihlásit")
          else
            @pageData.currentUser = res.user
            @_goToMainPage()
        )
        .finally(=>
          loginButton.prop('disabled', false)
          $('.loading-spinner').hide()
        )
    )
    $('#logout-button').on('click', =>
      $('.loading-spinner').show()
      fetch("/api/logout", method: 'POST')
        .then((resp) =>
          @pageData.currentUser = null
          $('#username-field').val('')
          @_showBlock('#login-block')
        )
        .finally(=>
          $('.loading-spinner').hide()
        )
    )

  homePage: ->
    if @pageData.currentUser
      unless $('#home').data('visited')
        @_goToMainPage()
    else
      @_showBlock('#login-block')
  
  
  _goToMainPage: ->    
    # Prepare for going back from main page
    @_showBlock('#logged-in-block')
    $('#to-mainpage-button').on('click', =>
      @_goToMainPage()
    )
    $('#ticket-code').text(@pageData.currentUser['fmt_ticket_code'])
    @appNavigator.pushPage(window.app.pageUrlPart('main.html'),
                           data: { autoPlay: true })
    $('#home').data('visited', true)


  _showBlock: (elementSel) ->
    for styleClass in document.querySelector(elementSel).classList
      if styleClass.match(/\w-group/)
        $('.' + styleClass).hide()
        $(elementSel).show()
        break
    

  mainPageInit: ->
    # Credits link
    $('#link-credits a').on('click', =>
      @appNavigator.pushPage(window.app.pageUrlPart('credits.html'))
    )
    # Init audio player
    window.app.audioPlayer.init()
    # Info/Help link
    for type in ['chapter', 'intermezzo']
      $("#acld-#{type}").on('click', (e) =>
        link = $(e.target)
        @appNavigator.pushPage(window.app.pageUrlPart('detail.html'), data: link.data())
      )
    # Back button
    $('#page-toolbar-main ons-back-button').on('click', (e) =>
      if window.app.audioPlayer.player.playing
        unless window.confirm("Zastavit přehrávač?")
          e.preventDefault()
          return
      @appNavigator.popPage()
    ) 


  mainPage: ->
    if @pageData.nextStep == 0
      $('#page-toolbar-main div.center').text("Poděkování")
      $('#locations-list').hide()
      $('#audio-player-container').hide()
      $('#thank-you').show()
      $('#link-credits').show()
      return
    
    locationsLength = window.app.audioPlayer.totalSteps = @pageData.locations.length
    lastLocation = @pageData.locations[locationsLength - 1]
    locationsList = $('#locations-list')
    locationsList.empty()
    for loc in @pageData.locations
      itemContent = "#{loc.num}. #{loc.title}"
      itemClass = ""
      if loc.num <= @pageData.nextStep
        if loc.num == @pageData.nextStep
          itemContent += "<div class=\"right\"><ons-icon icon=\"ion-ios-lock\" size=\"1.5em\"></ons-icon></div>"
          itemClass = "locked inactive"
        itemElement = ons.createElement("""
          <ons-list-item tappable
                         class="#{itemClass}"
                         data-step-num="#{loc.num}"
                         data-type="chapter"
                         data-location-id="#{loc.id}"
                         data-code="#{loc.code}">
            #{itemContent}
          </ons-list-item>
        """)
        locationsList.append(itemElement)
        if loc != lastLocation
          locationsList.append(ons.createElement("""
            <ons-list-item tappable
                           data-step-num="#{loc.num}"
                           data-type="intermezzo"
                           data-location-id="#{loc.id}"
                           data-code="#{loc.code}">
               – intermezzo #{loc.num} –
            </ons-list-item>
          """))
      else
        locationsList.append(ons.createElement("""
          <ons-list-item class="inactive">
            #{itemContent}
          </ons-list-item>
        """))

    $('#link-credits').show() if @pageData.nextStep > locationsLength

    # Load qrReader and enable locked item
    window.app.getQrReader().then(->
      $('#locations-list .locked').removeClass('inactive')
    )

    # Install items' listener
    listItems = $('#locations-list ons-list-item')
    listItems.on('click', (e) =>
      item = $(e.currentTarget)
      return if item.hasClass('inactive')

      itemStep = item.data('stepNum')
      if itemStep < @pageData.nextStep
        window.app.audioPlayer.playLocation($(e.currentTarget))
        window.app.audioPlayer.highlightCurrent()
      else if itemStep == @pageData.nextStep
        return unless window.confirm(
          "Odemknout lokaci?\n\n(bude potřeba povolit přístup ke kameře a poloze)")
          
        @appNavigator.pushPage(window.app.pageUrlPart('locked.html'),
                               data: { locationStep: itemStep })
    )
    
    # Automatic play
    data = @appNavigator.topPage.data
    if data.autoPlay
      delete data.autoPlay
      listItems.each((index, listElement) =>
        item = $(listElement)
        if item.data('stepNum') == @pageData.nextStep - 1 and
           item.data('type') == 'chapter'
          # play available chapter
          window.app.audioPlayer.playLocation(item)
        return
    )

    # Highlight playing item
    window.app.audioPlayer.highlightCurrent()

  detailPageInit: ->
    $('#detail-back-button').on('click', => @appNavigator.popPage())

      
  detailPage: ->
    detailData = @appNavigator.topPage.data
    locPath = '/location_details/' + detailData.locationId
    page = { chapter: 'about', intermezzo: 'hint'}[detailData.locationType]
    $('.loading-spinner').show()
    fetch("#{locPath}/#{page}.html")
      .then((response) => (response.text()))
      .then((content) =>
        compiled = template(content)
        $('#detail-content').html(
          compiled(locPath: locPath))
      )
      .finally(=>
        $('.loading-spinner').hide()
      )

  
  lockedPageInit: ->
    $('#cam-init-spinner').attr('src', window.app.spinnerUrl)
    $('div.qr-reader-part ons-button').hide()  
  
  lockedPage: ->
    if !navigator.geolocation || !navigator.geolocation.getCurrentPosition
      @__lockedShowNotice('#geoloc-no-access')
 
    qrReader = window.app.qrReader
    reader = qrReader.getCameraReader()
    qrReader.getCameras().then((devices) =>
      if !devices || devices.length == 0
        @__lockedShowNotice('#camera-no-access')
        return
 
      startReader = (camNum) =>
        qrBoxWidth = Math.round($('#qr-reader').width() * 0.85)
        reader.start(
          devices[camNum].id,
          {
            qrbox: { width: qrBoxWidth, height: qrBoxWidth }
          },
          ((decodedText, decodedResult) =>
            return unless decodedText
            @scanner.qrCode = decodedText
            reader.stop().then(=>
              $('.qr-reader-part').hide()
              @__processScannerData()
            )
          )
        )
        .then(=>
          $('#camera-switch-button').show() if devices.length > 1          
        )
        .catch((err) =>
          window.alert("Problem initializing QR reader!")
        )
    
      # switch camera button
      cameraNum = devices.length - 1
      $('#camera-switch-button').on('click', ->
        $(this).hide()
        reader.stop().then(->
          cameraNum = ((cameraNum + 1) % devices.length)
          startReader(cameraNum)
        )
      )

      # flashlight button
      # $('#flashlight-button').on('click', =>
      #   ... set flash light on
      # )
    
      # Geolocation coordinates
      geoLocTimer = null
      updateCoordinates = =>
        navigator.geolocation.getCurrentPosition(
          (position) =>
            accuracy = position.coords.accuracy
            isFresh = (Number(new Date) - position.timestamp) < 2000
            if accuracy < 55 and isFresh
              @scanner.geoCoords = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              }
              $('#geoloc-accuracy').hide()
              @__processScannerData()
            else
              $('#geoloc-accuracy strong').text(accuracy.toFixed())
              geoLocTimer = setTimeout(updateCoordinates, 2000)
          ,
          (error) =>
            reader.stop().catch((err) -> console.warn("Scanner: #{err}"))
            @__lockedShowNotice('#geoloc-no-access')
          ,
          enableHighAccuracy: true
        )

      startReader(cameraNum)
      $('#geoloc-accuracy').show()
      updateCoordinates()

      $('#page-toolbar-locked ons-back-button').on('click', =>
        reader.stop() if reader.getState() > 1
        clearTimeout(geoLocTimer) if geoLocTimer
        @appNavigator.popPage()   
      ) 
    ).catch((err) =>
      console.log(err)
      @__lockedShowNotice('#camera-no-access')
    )

  __lockedShowNotice: (section, stepNum = null) ->
    $('#scanner-section').hide()
    $(section).show()
    noticeButton = $('#notice-action-button ons-button')
    switch section
      when '#camera-no-access', '#geoloc-no-access'
        $('#unlock-error-message').show()
        noticeButton.text("Na začátek...")
        noticeButton.on('click', => location.reload())
      when '#location-unlocked'
        noticeButton.text("◀︎ Poslechnout")
        noticeButton.on('click', =>
          @appNavigator.popPage(data: { autoPlay: true })
        )
      else
        $('#unlock-error-message').show()
        noticeButton.text("◀︎ Zpět")
        noticeButton.on('click', =>
          @appNavigator.popPage(data: { autoPlay: true })
        )
    $('#notice-action-button').show()


  __processScannerData: ->
    # console.log("Scanner data:")
    # console.log(@scanner)
    return if !@scanner.geoCoords || !@scanner.qrCode

    step = @appNavigator.topPage.data.locationStep
    $('.loading-spinner').show()
    fetch("/api/unlock?step=#{step}",
            method: 'POST',
            body: JSON.stringify(@scanner))
      .then((resp) => resp.json())
      .then((res) =>
        checkErr = res.checkError
        if checkErr
          @__lockedShowNotice("#check-err-#{checkErr}")
          coords = @scanner.geoCoords
          $('#current-coords').html("(LAT: #{coords.lat} | LNG: #{coords.lng})")
        else
          @__lockedShowNotice('#location-unlocked', step)
      )
      .finally(=>
        $('.loading-spinner').hide()
      )


  creditsPage: ->
    return

